<template>
  <div>
    <v-app id="login">
      <v-progress-linear :active="!connected" color="error" :indeterminate="true"></v-progress-linear>
      <v-main>
        <v-app-bar class="elevation-1" color="info" app clipped-left extended extension-height="4">
          <v-toolbar-title class="headline text-uppercase">
            <span class="hidden-xs-only">FRS</span>
            <span class="font-weight-light">portal</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text href="https://mohntechnology.no" target="_blank" class="hidden-xs-only">
            <span class="mr-2">Mohn Technology</span>
          </v-btn>
        </v-app-bar>
        <v-container mt-5 fluid>
          <v-layout justify-center>
            <v-flex xs12 sm8 md4>
              <v-card class="elevation-11">
                <v-toolbar dark color="primary" class="elevation-2">
                  <v-toolbar-title>Authenticate</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-snackbar v-model="snackbar" :color="snackColor" :bottom="y === 'bottom'" :left="x === 'left'"
                    :multi-line="mode === 'multi-line'" :right="x === 'right'" :timeout="timeout" :top="y === 'top'"
                    :vertical="mode === 'vertical'" absolute>
                    {{ snackText }}
                    <template v-slot:action="{ }">
                      <v-btn text @click="snackbar = false">Close</v-btn>
                    </template>
                  </v-snackbar>
                </v-toolbar>
                <v-card-text>
                  <v-form ref="form" v-on:submit.prevent="submit" v-model="valid" lazy-validation>
                    <v-text-field prepend-icon="mdi-account" v-model="user" data-vv-name="user"  label="Email" type="text"
                      :rules="[passRules.required]"></v-text-field>
                    <v-text-field prepend-icon="mdi-lock" v-model="password"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passRules.required, passRules.min]"
                      :type="show ? 'text' : 'password'"   label="Password" hint="At least 4 characters"
                      @click:append="show = !show"></v-text-field>
                    <v-btn :disabled="!valid" left large color="primary" type="submit" :loading="loading"
                      @click="loader = 'loading'">
                      Login
                      <v-icon medium right>mdi-exit-to-app</v-icon>
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
/* global axios */
export default {
  data() {
    return {
      connected: true,
      show: false,
      user: "",
      password: "",
      valid: true,
      passRules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 4 || "Min 4 characters",
      },
      snackbar: false,
      y: "top",
      x: null,
      mode: "",
      timeout: 4000,
      snackColor: "error",
      snackText: "default",
      loader: null,
      loading: false,
      loginColor: "primary",
    };
  },
  created() {
    this.longPoll();
    setInterval(
      function () {
        this.longPoll();
      }.bind(this),
      1000
    );
  },
  methods: {
    longPoll: function () {
      axios
        .get("/api/v1/longpoll")
        .then(() => {
          this.connected = true;
        })
        .catch(() => {
          this.connected = false;
        });
    },
    submit() {
      if (!this.$refs.form.validate()) return;
      var bodyFormData = new FormData();
      bodyFormData.set("email", this.user?.trim());
      bodyFormData.set("password", this.password);
      axios({
        method: "post",
        url: "/api/v1/login",
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((response) => {
          if (response.status === 200) {
            window.location.href = "/";
          } else {
            // throw error and go to catch block
            throw new Error("Error");
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.snackColor = "error";
            this.snackText = "Connection error";
            this.snackbar = true;
          } else if (error.response.status === 401) {
            this.snackColor = "warning";
            this.snackText = "Wrong username or password";
            this.snackbar = true;
          } else {
            this.snackColor = "error";
            this.snackText = "FRS portal not responding";
            this.snackbar = true;
          }
          // console.log(response);
        });
    },
  },
};
</script>

<style scoped></style>