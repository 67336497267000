import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify, { iconfont: 'mdi' });

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // based on color-palette-890
        primary: '#486591',
        secondary: '#96c4de',
        accent: '#d6e6f3',
        error: '#ED6345',
        info: '#ffffff',
        success: '#D1EC9C',
        warning: '#df7021'
      },
      dark: {
        primary: '#daab79',
        secondary: '#004c56',
        accent: '#3f5375',
        error: '#ff6768',
        info: '#424242',
        success: '#67a173',
        warning: '#f4841a'
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
